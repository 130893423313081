import React, { useState } from "react";
import { GetProps, Layout, notification, Tabs, Tree, Typography } from "antd";
import "./Mysql.css";
import { useTranslation } from "react-i18next";
import { File } from "../../../../util/http";
import { Outlet } from "react-router-dom";
import DDL from "./DDL";
import Datasource from "./Datasource";

const { DirectoryTree } = Tree;
type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;
const { Text } = Typography;
const { TabPane } = Tabs;

export type Option = {
  label: string;
  value: string;
};

const Mysql: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="tabs-container">
      <Tabs
        defaultActiveKey="datasource"
        type={"card"}
        rootClassName="tab-pane"
      >
        <TabPane
          tab={t("mysqlTabDatasource")}
          key="datasource"
          className="tab-content"
        >
          <Datasource />
        </TabPane>
        <TabPane tab={t("mysqlTabDDL")} key="ddl" className="tab-content">
          <DDL />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Mysql;
