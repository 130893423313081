import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Form,
  GetProps,
  Input,
  Modal,
  notification,
  Tooltip,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import "./DDL.css";
import { useTranslation } from "react-i18next";
import { langs } from "@uiw/codemirror-extensions-langs";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";
import { githubLight } from "@uiw/codemirror-theme-github";
import { File, Http } from "../../../../util/http";
import { FullscreenOutlined } from "@ant-design/icons";
import { MysqlData } from "./_defaultProps";

const { DirectoryTree } = Tree;
type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;
const { Text } = Typography;

export type Option = {
  label: string;
  value: string;
};

const DDL: React.FC = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [generateForm] = Form.useForm();
  const [extension, setExtension] = useState<any>(langs.go());
  const [code, setCode] = useState(``);
  const [ddl, setDDL] = useState(``);
  const [modalDDL, setModalDDL] = useState(``);
  const [open, setOpen] = useState(false);
  const [mysqlFiles, setMysqlFiles] = useState<File[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([
    {
      title: "model",
      key: "model",
    },
  ]);
  useEffect(() => {
    const data = localStorage.getItem(MysqlData.DDLFormKey);
    const sql = localStorage.getItem(MysqlData.DDLSQLKey);
    if (sql) {
      setDDL(sql);
    }
    if (data) {
      generateForm.setFieldsValue(JSON.parse(data));
    } else {
      generateForm.setFieldsValue({
        schema: "",
        table: [],
        cache: false,
        strict: false,
        mysqlStyle: "",
        mysqlIgnoreColumns: "",
      });
    }
  }, [generateForm]);
  const onSubmit = (name: string) => {
    const data = generateForm.getFieldsValue();
    if (name === "download") {
      Http.MysqlDDLDownload(
        {
          schema: data.schema,
          style: data.style,
          cache: data.cache,
          strict: data.strict,
          ignoreColumns: data.ignoreColumns,
          sql: ddl,
        },
        () => {
          api.success({
            message: t("mysqlDownloadSuccess"),
            placement: "topRight",
          });
        },
        (err) => {
          api.error({
            message: t("mysqlDownloadError"),
            description: err,
            placement: "topRight",
          });
        },
      );
    } else {
      Http.MysqlDDLGen(
        {
          schema: data.schema,
          style: data.style,
          cache: data.cache,
          strict: data.strict,
          ignoreColumns: data.ignoreColumns,
          sql: ddl,
        },
        (data) => {
          if (!data) {
            return;
          }
          setMysqlFiles(data);
          let trees: TreeDataNode[] = [];
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            trees.push({
              title: (
                <Tooltip title={item.name}>
                  <Text ellipsis style={{ width: 150 }}>
                    {item.name}
                  </Text>
                </Tooltip>
              ),
              key: item.name,
              isLeaf: true,
            });
          }
          setTreeData([
            {
              title: "model",
              key: "model",
              children: trees,
            },
          ]);
          setSelectedKeys([data[0].name]);
          resetExtension(data[0].name);
          setCode(data[0].content);
        },
        (err) => {
          api.error({
            message: t("mysqlGenError"),
            description: err,
            placement: "topRight",
          });
        },
      );
    }
  };
  const onSelect: DirectoryTreeProps["onSelect"] = (keys, info) => {
    if (!keys) {
      return;
    }

    const key = keys[0];
    resetExtension(key as string);
    setSelectedKeys([key as string]);
    for (let i = 0; i < mysqlFiles.length; i++) {
      const file = mysqlFiles[i];
      const name = file.name;
      if (name === key) {
        setCode(file.content);
        break;
      }
    }
  };
  const resetExtension = (key: string) => {
    if (key) {
      const ext = key.split(".").pop();
      if (ext === "sql") {
        setExtension(langs.sql());
      } else {
        setExtension(langs.go());
      }
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        destroyOnClose
        width={"50%"}
        closable={false}
        okText={t("mysqlDDLModalOK")}
        cancelText={t("mysqlDDLModalCancel")}
        title={t("mysqlTabDDL")}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          setDDL(modalDDL);
          setOpen(false);
        }}
      >
        <CodeMirror
          className={"mysql-ddl-codemirror"}
          extensions={[
            langs.sql(),
            EditorView.theme({
              "&.cm-focused": {
                outline: "none",
              },
            }),
          ]}
          theme={githubLight}
          style={{
            overflow: "scroll",
            width: "100%",
            height: "60vh",
            margin: 10,
          }}
          value={modalDDL}
          onChange={(value) => {
            setModalDDL(value);
          }}
        />
      </Modal>
      <Flex className={"mysql-ddl"} gap={1}>
        <Flex className={"mysql-ddl-container"} vertical>
          <Form
            layout="vertical"
            form={generateForm}
            onValuesChange={(changedValues, allValues) => {
              localStorage.setItem(
                MysqlData.DDLFormKey,
                JSON.stringify(allValues),
              );
            }}
          >
            <Divider orientation="left">{t("mysqlGenerateTitle")}</Divider>

            <Flex
              vertical
              justify={"space-around"}
              gap={10}
              className={"mysql-option-panel"}
            >
              <Flex wrap gap={8}>
                <Form.Item
                  label={t("mysqlSchema")}
                  style={{ flex: 1 }}
                  name={"schema"}
                  tooltip={t("mysqlDDLSchemaTooltip")}
                >
                  <Input
                    allowClear
                    placeholder={`${t("formInputPrefix")}${t("mysqlSchema")}`}
                  />
                </Form.Item>
                <Form.Item
                  label={t("mysqlStyle")}
                  style={{ flex: 1 }}
                  name={"style"}
                  tooltip={t("mysqlStyleTooltip")}
                >
                  <Input
                    allowClear
                    placeholder={`${t("formInputPrefix")}${t("mysqlStyle")}`}
                  />
                </Form.Item>
              </Flex>
              <Flex wrap gap={8}>
                <Form.Item
                  label={t("mysqlIgnoreColumns")}
                  style={{ flex: 1 }}
                  name={"ignoreColumns"}
                  tooltip={t("mysqlIgnoreColumnsTooltip")}
                >
                  <Input
                    allowClear
                    placeholder={`${t("formInputPrefix")}${t("mysqlIgnoreColumns")}`}
                  />
                </Form.Item>
              </Flex>
              <Flex justify={"space-around"} flex={1} gap={10}>
                <Form.Item
                  label={""}
                  style={{ flex: 1 }}
                  name={"cache"}
                  valuePropName="checked"
                >
                  <Checkbox>{t("mysqlCache")}</Checkbox>
                </Form.Item>
                <Form.Item
                  label={""}
                  style={{ flex: 1 }}
                  name={"strict"}
                  tooltip={t("mysqlStrictTooltip")}
                  valuePropName="checked"
                >
                  <Checkbox>{t("mysqlStrict")}</Checkbox>
                </Form.Item>
              </Flex>
            </Flex>
          </Form>

          <Divider orientation="left">{t("mysqlDDLTitle")}</Divider>

          <div
            style={{
              overflowX: "hidden",
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CodeMirror
              className={"mysql-ddl-codemirror"}
              extensions={[
                langs.sql(),
                EditorView.theme({
                  "&.cm-focused": {
                    outline: "none",
                  },
                }),
              ]}
              theme={githubLight}
              style={{
                width: "100%",
                overflow: "scroll",
              }}
              value={ddl}
              onChange={(value) => {
                setDDL(value);
                localStorage.setItem(MysqlData.DDLSQLKey, value);
              }}
            />
            <span>
              <Tooltip title={t("tooltipFullScreen")}>
                <FullscreenOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setModalDDL(ddl);
                    setOpen(true);
                  }}
                />
              </Tooltip>
            </span>
          </div>
          <Flex justify={"flex-end"} gap={8} style={{ padding: "24px 0" }}>
            <Button
              type={"dashed"}
              onClick={() => {
                onSubmit("download");
              }}
            >
              {t("mysqlDownload")}
            </Button>
            <Button
              type={"primary"}
              onClick={() => {
                onSubmit("submit");
              }}
            >
              {t("mysqlGenerate")}
            </Button>
          </Flex>
        </Flex>

        <DirectoryTree
          showLine
          showIcon={false}
          defaultExpandAll
          onSelect={onSelect}
          treeData={treeData}
          selectedKeys={selectedKeys}
          style={{
            width: 200,
            background: "white",
          }}
        />
        <CodeMirror
          className={"mysql-ddl-codemirror"}
          value={code}
          extensions={[
            extension,
            EditorView.theme({
              "&.cm-focused": {
                outline: "none",
              },
            }),
          ]}
          readOnly
          theme={githubLight}
          style={{
            flex: 1,
            height: "100%",
            background: "white",
            overflow: "scroll",
          }}
        />
      </Flex>
    </>
  );
};

export default DDL;
