import React from "react";
import { FormListFieldData } from "antd/es/form/FormList";
import RouteGroupOptionPanel from "./RouteGroupOptionPanel";
import RoutePanel from "./RoutePanel";
import type { FormInstance } from "antd/es/form/hooks/useForm";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

interface RouteGroupPanelProps {
  routeGroupField: FormListFieldData;
  form: FormInstance;
}

const RouteGroupPanel: React.FC<
  RouteGroupPanelProps & React.RefAttributes<HTMLDivElement>
> = (props) => {
  const routeGroupField = props.routeGroupField;
  const { t } = useTranslation();
  return (
    <div>
      <Collapse
        style={{ marginBottom: 16 }}
        items={[
          {
            key: "route-group-option",
            label: t("formRouteGroupOptionTitle"),
            children: (
              <RouteGroupOptionPanel routeGroupField={routeGroupField} />
            ),
          },
        ]}
      />

      <RoutePanel routeGroupField={routeGroupField} form={props.form} />
    </div>
  );
};

export default RouteGroupPanel;
